import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Overlay from '../../../../common/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';

interface MainSiderProps {
  forceCollapse?: boolean;
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
  hidden: boolean;
}

const MainSider: React.FC<MainSiderProps> = ({
  forceCollapse = false,
  isCollapsed,
  setCollapsed,
  hidden = false,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();
  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setCollapsed(true);
      }
    },
    [setCollapsed],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.Sider
        ref={ref}
        trigger={null}
        hidden={hidden}
        collapsed={forceCollapse ? true : !isDesktop && isCollapsed}
        collapsedWidth={forceCollapse ? 80 : tabletOnly ? 80 : 0}
        collapsible={isCollapsible}
        width={260}
        {...props}
      >
        <SiderLogo
          forcedCollapse={forceCollapse}
          isSiderCollapsed={isCollapsed}
          toggleSider={() => setCollapsed(true)}
        />
        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} isCollapsed />
        </S.SiderContent>
      </S.Sider>
      {mobileOnly && <Overlay onClick={() => setCollapsed(true)} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
