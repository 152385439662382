import { IconMore } from '@app/assets/slump-icons';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Table } from '@app/components/common/Table/Table';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import { notificationController } from '@app/controllers/notificationController';
import { TableDataType } from '@app/constants/enums/tableDataType';
import { DeviceModel } from '@app/domain/interfaces/device/deviceModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IDeviceService, { DeviceService } from '@app/services/deviceService';
import { setHeader } from '@app/store/slices/headerSlice';
import { Row, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import DrawerLinkConcreteMixer from '@app/components/drawers/device/DrawerLinkConcreteMixer';
import { DrawerSuccess } from '@app/components/common/Drawer/DrawerSuccess/DrawerSuccess';
import { Modal } from '@app/components/common/Modal/Modal';
import DrawerViewDevice from '@app/components/drawers/device/DrawerViewDevice';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { hasAccessByPermission } from '@app/controllers/accessController';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { isUserMaster, readUserFilter } from '@app/services/localStorage.service';
import Dashboard from '@app/components/dashboard/Dashboard';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { useNavigate } from 'react-router-dom';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const deviceService: IDeviceService = new DeviceService();

const DeviceDashboard: React.FC = () => {
  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [device, setDevice] = useState<DeviceModel>({});
  const [idMixerSelected, setIdMixerSelected] = useState(0);
  const [drawerSuccessVisible, setDrawerSuccessVisible] = useState(false);
  const [drawerViewVisible, setDrawerViewVisible] = useState(false);
  const [drawerLinkConcreteMixerVisible, setDrawerLinkConcreteMixerVisible] = useState(false);
  const [modalUnlinkVisible, setModalUnlinkVisible] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState<ClientModel>({} as ClientModel);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [tableData, setTableData] = useState<{ data: DeviceModel[]; loading: boolean }>({
    data: [],
    loading: false,
  });

  const handleOnCloseDrawerView = () => {
    setDevice({});
    setDrawerViewVisible(false);
  };

  const handleExportToExcel = () => {
    genericExportToExcel(
      'Dispositivos',
      columns,
      tableData?.data?.map((d) => ({
        ...d,
        tipo: d.tipo ? 'Tablet' : 'Hermes',
        connectionState: d.connectionState ? 'Sim' : 'Não',
        idBetoneira: d.idBetoneira ? 'Vinculado' : 'Sem vínculo',
      })) ?? [],
    );
  };

  const handleOnCloseDrawerLinkMixer = (finish: boolean) => {
    if (idMixerSelected && finish) {
      deviceService.api
        .post('vincularBetoneira', { idBetoneira: idMixerSelected, idDispositivo: device.id })
        .then(() => {
          setDrawerLinkConcreteMixerVisible(false);
          setDrawerSuccessVisible(true);
          fetch();
          setIdMixerSelected(0);
          setDevice({});
          setTimeout(() => {
            setDrawerSuccessVisible(false);
          }, 1700);
        })
        .catch((error) => {
          notificationController.error(error);
          setIdMixerSelected(0);
          setDevice({});
        });
    } else {
      setDrawerLinkConcreteMixerVisible(false);
      setIdMixerSelected(0);
      setDevice({});
    }
  };
  const handleClickViewDevice = (device: DeviceModel) => {
    setDevice(device);
    setDrawerViewVisible(true);
  };
  const handleClickLinkMixer = (device: DeviceModel) => {
    setDevice(device);
    setDrawerLinkConcreteMixerVisible(true);
  };
  const handleClickUnlinkMixer = (device: DeviceModel) => {
    setDevice(device);
    setModalUnlinkVisible(true);
  };
  const handleUnlinkDevice = () => {
    setIsLoading(true);
    deviceService.api
      .put(`desvincularBetoneira/${device.id}`)
      .then(() => {
        setTableData((prevState) => ({
          ...prevState,
          data: prevState.data.map((item) => {
            if (item.id == device.id) {
              return {
                ...item,
                idBetoneira: 0,
                numeroBT: '',
              };
            }
            return item;
          }),
        }));
        setIsLoading(false);
        setModalUnlinkVisible(false);
        notificationController.success({ message: 'Dispositivo desvinculado' });
      })
      .catch((error) => {
        notificationController.error(error);
        setModalUnlinkVisible(false);
        setIsLoading(false);
      });
  };

  const handleNewDeviceClick = () => {
    navigate('/gestao/dispositivos/cadastrar');
  };

  const handleClickEditDevice = (device: DeviceModel, id: number) => {
    setDevice(device);
    sessionStorage.setItem('id', id.toString());
    navigate(`/gestao/dispositivos/${device.id}`);
  };

  const handleDeleteDevice = () => {
    setIsLoading(true);
    deviceService
      .delete(`deletar/${device.id}`)
      .then(() => {
        setTableData({ ...tableData, data: tableData.data.filter((item) => item.id !== device.id) });
        setIsLoading(false);
        setModalDeleteVisible(false);
        notificationController.success({ message: 'Dispositivo excluído' });
      })
      .catch((error) => {
        notificationController.error(error);
        setModalDeleteVisible(false);
        setIsLoading(false);
      });
  };

  const handleClickDeleteDevice = (device: DeviceModel) => {
    setDevice(device);
    setModalDeleteVisible(true);
  };

  const handleDeviceFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toUpperCase().trim();
      setTableData((prevState) => ({
        ...prevState,
        data: devices?.filter(
          (item) =>
            item.id?.toString().toLowerCase().includes(inputValue) ||
            item.imei?.toLowerCase().replaceAll('-', '').includes(inputValue.replaceAll('-', '')) ||
            item.deviceId?.toLowerCase().includes(inputValue) ||
            item.numeroBT?.toLowerCase().includes(inputValue),
        ),
      }));
    } else {
      setTableData((prevState) => ({ ...prevState, data: devices }));
    }
  };

  const columns: ColumnsType<DeviceModel> = [
    {
      title: 'Apelido',
      dataIndex: 'deviceId',
      showSorterTooltip: false,
      width: '10%',
      render: (idBetoneira: number, device: DeviceModel) => {
        return device.numeroBT ? (
          <Tooltip placement="topLeft" title={device.numeroBT}>
            <span>{device.deviceId}</span>
          </Tooltip>
        ) : (
          <span>{device.deviceId}</span>
        );
      },
      sorter: (a, b) => (a.deviceId && b.deviceId ? a.deviceId.localeCompare(b.deviceId) : 0),
      ellipsis: true,
    },
    {
      title: 'BT',
      dataIndex: 'numeroBT',
      showSorterTooltip: false,
      width: '10%',
      render: (idBetoneira: number, device: DeviceModel) => {
        return device.deviceId ? (
          <Tooltip placement="topLeft" title={device.deviceId}>
            <span>{device.numeroBT}</span>
          </Tooltip>
        ) : (
          <span>{device.numeroBT}</span>
        );
      },
      sorter: (a, b) => (a.numeroBT && b.numeroBT ? a.numeroBT.localeCompare(b.numeroBT) : 0),
      ellipsis: true,
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      showSorterTooltip: false,
      width: '25%',
      sorter: (a, b) => (a.imei && b.imei ? a.imei.localeCompare(b.imei) : 0),
      ellipsis: true,
    },
    {
      title: 'Versão do dispositivo',
      dataIndex: 'tipo',
      showSorterTooltip: false,
      width: '15%',
      render: (tipo: string) => {
        return tipo === 'H' ? <Row align="middle">Hermes</Row> : <Row align="middle">Tablet</Row>;
      },
      sorter: (a, b) => (a.tipo && b.tipo ? a.tipo.localeCompare(b.tipo) : 0),
      ellipsis: true,
    },
    {
      title: 'Conectado',
      dataIndex: 'connectionState',
      showSorterTooltip: false,
      width: '12%',
      render: (connectionState) => {
        return connectionState === 'Connected' ? (
          <Tag style={{ padding: '0.375rem 1rem', color: '#083F18', borderRadius: ' 0.375rem' }} color="#E9F4EE">
            Sim
          </Tag>
        ) : (
          <Tag style={{ padding: '0.375rem 1rem', color: '#620E12', borderRadius: ' 0.375rem' }} color="#FEE9EA">
            Não
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'idBetoneira',
      showSorterTooltip: false,
      width: '8%',
      render: (idBetoneira: number) => {
        return idBetoneira !== 0 ? (
          <Tag style={{ padding: '0.375rem 1rem', color: '#083F18', borderRadius: ' 0.375rem' }} color="#E9F4EE">
            Vinculado
          </Tag>
        ) : (
          <Tag style={{ padding: '0.375rem 0.5rem', color: '#620E12', borderRadius: ' 0.375rem' }} color="#FEE9EA">
            Sem vínculo
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10%',
      sortDirections: [],
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      sorter: (a: DeviceModel, b: DeviceModel) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      render: (id, device) => {
        const canEdit = hasAccessByPermission('device', 'U');
        const canDelete = hasAccessByPermission('device', 'D');
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {(canEdit || canDelete) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {canEdit && device.idBetoneira === 0 && (
                      <MenuItem>
                        <Button type="text" onClick={() => handleClickEditDevice(device, id)}>
                          Editar
                        </Button>
                      </MenuItem>
                    )}
                    {canEdit && device.idBetoneira === 0 && device.idCliente ? (
                      <MenuItem>
                        <Button type="text" onClick={() => handleClickLinkMixer(device)}>
                          Vincular
                        </Button>
                      </MenuItem>
                    ) : (
                      <></>
                    )}
                    {canEdit && device.idBetoneira !== 0 && (
                      <MenuItem>
                        <Button type="text" onClick={() => handleClickUnlinkMixer(device)}>
                          Desvincular
                        </Button>
                      </MenuItem>
                    )}
                    {device.idBetoneira !== undefined && device.idBetoneira !== 0 && (
                      <>
                        <MenuItem>
                          <Button type="text" onClick={() => handleClickViewDevice(device)}>
                            Visualizar
                          </Button>
                        </MenuItem>
                        <MenuItem>
                          <Button type="text" onClick={() => navigate(`/relatorio/erros-betoneira/${device.deviceId}`)}>
                            Relatório de Erros
                          </Button>
                        </MenuItem>
                      </>
                    )}
                    {canEdit && device.idBetoneira !== 0 && (
                      <MenuItem>
                        <Button
                          type="text"
                          onClick={() =>
                            device.connectionState == 'Connected'
                              ? navigate(`/gestao/dispositivos/${device.id}/twin`)
                              : navigate(`/gestao/dispositivos/${device.id}/twin/visualizar`)
                          }
                        >
                          Configurar
                        </Button>
                      </MenuItem>
                    )}
                    {canDelete && !device.idBetoneira && (
                      <MenuItem>
                        <Button type="text" onClick={() => handleClickDeleteDevice(device)}>
                          Deletar
                        </Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const fetch = useCallback(() => {
    setTableData((prevState) => ({ ...prevState, loading: true }));
    deviceService
      .getArray('obter')
      .then((res) => {
        if (isMounted.current) {
          setDevices(res);
          setTimeout(() => {
            setTableData((prevState) => ({ ...prevState, data: res, loading: false }));
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error(error);
        setTableData((prevState) => ({ ...prevState, loading: false }));
      });
  }, [isMounted]);

  useEffect(() => {
    const filter = readUserFilter();

    if (isUserMaster()) {
      fetch();
      setClient(filter.client ?? ({} as ClientModel));
    } else if (filter.client?.id) {
      fetch();
      setClient(filter.client);
    }
  }, [fetch]);

  useEffect(() => {
    if (updateTable) {
      fetch();
      setUpdateTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable]);

  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeader({
          title: 'Dispositivos',
          buttonText: isUserMaster() ? 'Novo dispositivo' : '',
          handleButtonClick: handleNewDeviceClick,
          tableData: tableData.data,
          tableDataType: TableDataType.Device,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  useEffect(() => {
    if (!modalUnlinkVisible) {
      setDevice({});
    }
  }, [modalUnlinkVisible]);

  useEffect(() => {
    if (!modalDeleteVisible) {
      setDevice({});
    }
  }, [modalDeleteVisible]);

  return (
    <>
      <Spinner spinning={tableData.loading}>
        <Dashboard
          title={`Dispositivos   ${client?.razaoSocial ? `- ${client?.razaoSocial}` : ''}`}
          buttonText={hasAccessByPermission('device', 'C') ? 'Cadastrar novo dispositivo' : ''}
          handleButtonClick={handleNewDeviceClick}
          table={<Table bordered={true} columns={columns} dataSource={tableData.data} loading={tableData.loading} />}
          placeholderTextSearch="Pesquisar por IMEI ou apelido"
          handleSearchOnChange={handleDeviceFilter}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
      <Modal
        title="Desvincular dispositivo"
        open={modalUnlinkVisible}
        onOk={handleUnlinkDevice}
        onCancel={() => setModalUnlinkVisible(false)}
      >
        <SpinnerSlump spinning={isLoading}>
          Tem certeza que deseja desvincular este dispositivo?
          <br />
        </SpinnerSlump>
      </Modal>
      <Modal
        title="Deletar dispositivo"
        open={modalDeleteVisible}
        onOk={handleDeleteDevice}
        onCancel={() => setModalDeleteVisible(false)}
      >
        Tem certeza que deseja excluir este dispositivo?
        <br />
        <SpinnerSlump spinning={isLoading}></SpinnerSlump>
      </Modal>
      <DrawerViewDevice visible={drawerViewVisible} onClose={handleOnCloseDrawerView} device={device} />
      <DrawerLinkConcreteMixer
        device={device}
        visible={drawerLinkConcreteMixerVisible}
        setDevice={setDevice}
        onClose={handleOnCloseDrawerLinkMixer}
        setUpdateTable={setUpdateTable}
      />
      <DrawerSuccess
        text="Dispositivo vinculado com sucesso"
        title=""
        placement="right"
        open={drawerSuccessVisible}
        onClose={() => setDrawerSuccessVisible(false)}
        style={{ overflowX: 'hidden' }}
      />
      <PageTitle>Dispositivos</PageTitle>
    </>
  );
};

export default DeviceDashboard;
