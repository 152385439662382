import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './SiderMenu.styles';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { RouteSlumpMixConfig, RoutesSlumpMix } from '../../../../router/routes';
import { hasAccessByRole, hasAccessByRoles } from '@app/controllers/accessController';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
  isCollapsed: boolean;
}

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed, isCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const routes = RoutesSlumpMix?.filter((nav) => nav.isSideMenu);
  const sidebarNavFlat = routes?.reduce((result: RouteSlumpMixConfig[], current) => {
    return result.concat(current.children && current.children.length > 0 ? current.children : current);
  }, []);

  const currentMenuItem = sidebarNavFlat?.find(({ path }) => path === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = RoutesSlumpMix.find(({ children }) => children?.some(({ path }) => path === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
    >
      {routes?.map((nav) => {
        if (nav.children && nav.children.length > 0) {
          if (hasAccessByRoles(nav.children.map((child) => child.role ?? ''))) {
            return (
              <Menu.SubMenu key={nav.key} title={t(nav.title)} icon={nav.icon} popupClassName="">
                {nav.children
                  .filter((childNav) => childNav.isSideMenu)
                  .map(
                    (childNav) =>
                      childNav.role &&
                      hasAccessByRole(childNav.role) && (
                        <Menu.Item key={childNav.key} title="">
                          <Link to={childNav.path || ''}>{t(childNav.title)}</Link>
                        </Menu.Item>
                      ),
                  )}
              </Menu.SubMenu>
            );
          }
        } else {
          if (nav.role && hasAccessByRole(nav.role)) {
            return (
              <Menu.Item key={nav.key} title="" icon={nav.icon}>
                <Link to={nav.path || ''}>{t(nav.title)}</Link>
              </Menu.Item>
            );
          } else if (nav.roles?.length && hasAccessByRoles(nav.roles)) {
            return (
              <Menu.Item key={nav.key} title="" icon={nav.icon}>
                <Link to={nav.path || ''}>{t(nav.title)}</Link>
              </Menu.Item>
            );
          }
        }
      })}
    </S.Menu>
  );
};

export default SiderMenu;
