import { ReportBaseModel } from '@app/domain/interfaces/report/reportBaseModel';
import { ReportDriverRootModel } from '@app/domain/interfaces/report/reportDriverModel';
import { ReportFilterModel } from '@app/domain/interfaces/report/reportFilterModel';
import { ReportTimeDetailedModel, ReportTimeModel } from '@app/domain/interfaces/report/reportTimeModel';
import { ReportTraceRootModel } from '@app/domain/interfaces/report/reportTraceModel';
import { ReportWaterModel } from '@app/domain/interfaces/report/reportWaterModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { ReportConcreteMixerModel } from '@app/domain/interfaces/report/reportConcreteMixerModel';
import { ReportWaterDetailModel } from '@app/domain/interfaces/report/reportWaterDetailModel';
import { ReportDriverDetailModel } from '@app/domain/interfaces/report/reportDriverDetailModel';
import { ReportTraceDetailModel } from '@app/domain/interfaces/report/reporTraceDetailModel';
import { AxiosResponse } from 'axios';
import {
  ReportMixerErrorAnalyticModel,
  ReportMixerErrorDeviceAnalyticModel,
} from '@app/domain/interfaces/report/reportMixerErrorAnalytic';
import { TripAnalyticModel } from '@app/domain/interfaces/report/reportTripAnalyticModel';
import { ShippingListModel } from '@app/domain/interfaces/report/reportShippingListModel';
import { ReportTripErrorAnalytic } from '@app/domain/interfaces/report/reportTripErrorAnalytic';
import { ReportTripErrorDetailAnalytic } from '@app/domain/interfaces/report/reportTripErrorDetailAnalytic';

export default interface IReportService extends IApiServiceBase<ReportFilterModel, ReportBaseModel> {
  getWaterUseReport(filter: ReportFilterModel): Promise<ReportWaterModel>;
  getTimeReport(filter: ReportFilterModel): Promise<ReportTimeModel>;
  getTraceReport(filter: ReportFilterModel): Promise<ReportTraceRootModel>;
  getDriverReport(filter: ReportFilterModel): Promise<ReportDriverRootModel>;
  getMixerReportByDevice(deviceId: string, betoneira: string): Promise<ReportConcreteMixerModel>;
  getMixerReportByDeviceMonitoring(deviceId: string): Promise<ReportConcreteMixerModel>;
  getMixerReportByConstruction(constructionId: number): Promise<ReportConcreteMixerModel>;
  getReportWaterDetailed(filter: ReportFilterModel): Promise<ReportWaterDetailModel>;
  getReportTimeDetailed(filter: ReportFilterModel): Promise<ReportTimeDetailedModel>;
  getReportTraceDetailed(filter: ReportFilterModel): Promise<ReportTraceDetailModel>;
  getReportDriverDetailed(filter: ReportFilterModel): Promise<ReportDriverDetailModel>;
  existsMixerReportAnlyticInExecution(): Promise<boolean>;
  generateMixerReportAnlytic(): Promise<AxiosResponse>;
  getMixerReportAnlytic(): Promise<ReportMixerErrorAnalyticModel>;
  getMixerReportAnlyticByDeviceId(deviceId: string): Promise<ReportMixerErrorDeviceAnalyticModel>;
  getTripReportAnalytic(idViagem: number): Promise<TripAnalyticModel>;
  getTripReportErrorAnalytic(dataInicio: Date, dataFim: Date): Promise<ReportTripErrorAnalytic>;
  getTripReportErrorAnalyticByIdTrip(idViagem: string): Promise<ReportTripErrorDetailAnalytic>;
  getShippingListReport(
    dataInicio: Date,
    dataFim: Date,
    codigoFilialSiac?: number,
    betoneira?: string,
  ): Promise<ShippingListModel[]>;
}

export class ReportService extends ApiServiceBase<ReportFilterModel, ReportBaseModel> implements IReportService {
  constructor() {
    super('admin/relatorios');
  }

  async getWaterUseReport(filter: ReportFilterModel): Promise<ReportWaterModel> {
    return (await this.api.post(`uso-agua`, filter))?.data;
  }

  async getTimeReport(filter: ReportFilterModel): Promise<ReportTimeModel> {
    return (await this.api.post(`tempo`, filter))?.data;
  }

  async getTraceReport(filter: ReportFilterModel): Promise<ReportTraceRootModel> {
    return (await this.api.post(`traco`, filter))?.data;
  }

  async getDriverReport(filter: ReportFilterModel): Promise<ReportDriverRootModel> {
    return (await this.api.post(`motorista`, filter))?.data;
  }

  async getReportWaterDetailed(filter: ReportFilterModel): Promise<ReportWaterDetailModel> {
    return (await this.api.post(`uso-agua-detalhado`, filter))?.data;
  }

  async getReportTimeDetailed(filter: ReportFilterModel): Promise<ReportTimeDetailedModel> {
    return (await this.api.post(`tempo-detalhado`, filter, { timeout: 1000000 }))?.data;
  }

  async getReportTraceDetailed(filter: ReportFilterModel): Promise<ReportTraceDetailModel> {
    return (await this.api.post(`traco-detalhado`, filter))?.data;
  }

  async getReportDriverDetailed(filter: ReportFilterModel): Promise<ReportDriverDetailModel> {
    return (await this.api.post(`motorista-detalhado`, filter))?.data;
  }

  async getMixerReportByDevice(deviceId: string, betoneira: string): Promise<ReportConcreteMixerModel> {
    return (await this.api.get(`betoneira/obter-por-device-id/${deviceId}/${betoneira}`))?.data;
  }

  async getMixerReportByDeviceMonitoring(deviceId: string): Promise<ReportConcreteMixerModel> {
    return (await this.api.get(`betoneira/obter-por-device-id-monitoramento/${deviceId}`))?.data;
  }

  async getMixerReportByConstruction(constructionId: number): Promise<ReportConcreteMixerModel> {
    return (await this.api.get(`betoneira/obter-por-obra-id/${constructionId}`))?.data;
  }

  //mixer error report analytic
  async existsMixerReportAnlyticInExecution(): Promise<boolean> {
    return (await this.api.get(`betoneira/existe-relatorio-em-execucao`)).data;
  }

  async generateMixerReportAnlytic(): Promise<AxiosResponse> {
    return await this.api.post(`betoneira/gerar-erros-dispositivos`);
  }

  async getMixerReportAnlytic(): Promise<ReportMixerErrorAnalyticModel> {
    return (await this.api.get(`betoneira/obter-erros-dispositivos`)).data;
  }

  async getMixerReportAnlyticByDeviceId(deviceId: string): Promise<ReportMixerErrorDeviceAnalyticModel> {
    return (await this.api.get(`betoneira/obter-erros-dispositivos-por-device-id/${deviceId}`)).data;
  }

  async getTripReportAnalytic(idViagem: number): Promise<TripAnalyticModel> {
    return (await this.api.get(`analise-viagem/${idViagem}`)).data;
  }

  async getTripReportErrorAnalytic(dataInicio: Date, dataFim: Date): Promise<ReportTripErrorAnalytic> {
    return (await this.api.post(`analise-viagem/erros`, { DataHoraInicio: dataInicio, DataHoraFim: dataFim })).data;
  }

  async getTripReportErrorAnalyticByIdTrip(idViagem: string): Promise<ReportTripErrorDetailAnalytic> {
    return (await this.api.get(`analise-viagem/erros/${idViagem}`)).data;
  }

  async getShippingListReport(
    dataInicio: Date,
    dataFim: Date,
    codigoFilialSiac?: number,
    betoneira?: string,
  ): Promise<ShippingListModel[]> {
    return (
      await this.api.post(`lista-remessas`, {
        dataInicio,
        dataFim,
        codigoFilialSiac,
        betoneira,
      })
    ).data;
  }
}
