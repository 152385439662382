import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { Select } from '@app/components/common/selects/Select/Select';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import Dashboard from '@app/components/dashboard/Dashboard';
import { notificationController } from '@app/controllers/notificationController';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { ShippingListModel } from '@app/domain/interfaces/report/reportShippingListModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';
import IGroupService, { GroupService } from '@app/services/groupService';
import IReportService, { ReportService } from '@app/services/reportService';
import { setHeader } from '@app/store/slices/headerSlice';
import { Col, DatePicker, Row } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const reportService: IReportService = new ReportService();
const branchService: IClientBranchService = new ClientBranchService();
const groupService: IGroupService = new GroupService();
const concreteMixerService: IConcreteMixerService = new ConcreteMixerService();

const filterTypeOptions = [
  { value: 1, label: 'Betoneira' },
  { value: 2, label: 'Filial' },
];

export const ShippingListAnalyticDashboard: FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<ShippingListModel[]>();
  const [reportFiltered, setReportFiltered] = useState<ShippingListModel[]>();
  const [dateToSearch, setDateToSearch] = useState<moment.Moment | null>(null);
  const [branchToSearch, setBranchToSearch] = useState<number | null>(null);
  const [branches, setBranches] = useState<ClientBranchModel[]>([]);
  const [mixers, setMixers] = useState<ConcreteMixerModel[]>();
  const [groups, setGroups] = useState<GroupModel[]>();
  const [group, setGroup] = useState<GroupModel>();
  const [concreteMixer, setConcreteMixer] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<number | null>(null);

  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setReportFiltered(
        report?.filter(
          (item) =>
            item.numeroNotaFiscal?.toString().toLowerCase().includes(inputValue) ||
            item.idViagem?.toString().toLowerCase().includes(inputValue) ||
            item.betoneira?.toString().toLowerCase().includes(inputValue) ||
            item.deviceId?.toString().toLowerCase().includes(inputValue) ||
            item.motorista?.toString().toLowerCase().includes(inputValue) ||
            item.dataEmissaoNotaFiscal?.toString().toLowerCase().includes(inputValue) ||
            item.dataHora?.toString().toLowerCase().includes(inputValue) ||
            (item.distanciaFilialObra ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            (item.pontoDeCarga ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            item.obra?.toString().toLowerCase().includes(inputValue) ||
            item.filial?.toString().toLowerCase().includes(inputValue),
        ) ?? [],
      );
    } else {
      setReportFiltered(report ?? []);
    }
  };

  const handleFetchReportData = () => {
    setLoading(true);

    reportService
      .getShippingListReport(
        dateToSearch?.toDate() ?? new Date(),
        dateToSearch?.toDate() ?? new Date(),
        branchToSearch ? branchToSearch : undefined,
        concreteMixer ? concreteMixer : undefined,
      )
      .then((res) => {
        setReport(res);
        setReportFiltered(res);
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
  };

  const handleChangeGroup = (id: number) => {
    setGroup(groups?.find((group) => group.id == (id as number)) ?? ({} as GroupModel));
  };

  const fetchCombosData = async () => {
    try {
      setLoading(true);

      const groupIds: number[] = [];
      const resGroups = await groupService.getArray('');
      const resBranches = await branchService.getArray('');

      resBranches?.filter((cb) => cb.codigoFilialSiac).map((cb) => groupIds.push(cb.agrupamentos.idAgrupamento));

      setGroups(resGroups.filter((g) => groupIds.includes(g.id)));
      setBranches(resBranches);

      setLoading(false);
    } catch (error) {
      notificationController.error(error as ArgsProps);
      setLoading(false);
    }
  };

  const fetchConcreteMixers = useCallback(() => {
    setLoading(true);
    concreteMixerService
      .getArray(`obter`)
      .then((res: ConcreteMixerModel[]) => {
        setMixers(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        notificationController.error({ message: 'Erro ao obter as betoneiras.' });
      });
  }, []);

  const columns: ColumnsType<ShippingListModel> = [
    {
      title: 'Viagem',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
      sorter: (a, b) => a.idViagem!.localeCompare(b.idViagem!),
    },
    {
      title: 'NF',
      dataIndex: 'numeroNotaFiscal',
      showSorterTooltip: false,
      sorter: (a, b) => a.numeroNotaFiscal!.localeCompare(b.numeroNotaFiscal!),
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      sorter: (a, b) => a.motorista!.localeCompare(b.motorista!),
      ellipsis: true,
    },
    {
      title: 'Betoneira',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      sorter: (a, b) => a.betoneira!.localeCompare(b.betoneira!),
    },
    {
      title: 'DeviceId',
      dataIndex: 'deviceId',
      showSorterTooltip: false,
      sorter: (a, b) => a.deviceId!.localeCompare(b.deviceId!),
    },
    {
      title: 'Data/Hora Remessa',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.dataHora && b.dataHora ? new Date(a.dataHora).getTime() - new Date(b.dataHora).getTime() : 0,
      width: '15%',
      render: (dataHora: Date) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },

    {
      title: 'Data Emissão NF',
      dataIndex: 'dataEmissaoNotaFiscal',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.dataEmissaoNotaFiscal && b.dataEmissaoNotaFiscal
          ? new Date(a.dataEmissaoNotaFiscal).getTime() - new Date(b.dataEmissaoNotaFiscal).getTime()
          : 0,
      width: '15%',
      render: (dataEmissaoNotaFiscal: Date) =>
        dataEmissaoNotaFiscal ? moment(dataEmissaoNotaFiscal).format('DD/MM/YYYY HH:mm') + 'h' : '-',
    },
    {
      title: 'Filial',
      dataIndex: 'filial',
      showSorterTooltip: false,
      sorter: (a, b) => a.filial!.localeCompare(b.filial!),
      ellipsis: true,
    },
    {
      title: 'Obra',
      dataIndex: 'obra',
      showSorterTooltip: false,
      sorter: (a, b) => a.obra!.localeCompare(b.obra!),
      ellipsis: true,
    },
    {
      title: 'Distância Filial/Obra',
      dataIndex: 'distanciaFilialObra',
      showSorterTooltip: false,
      sorter: (a, b) => a.distanciaFilialObra!.localeCompare(b.distanciaFilialObra!),
      ellipsis: true,
    },
    {
      title: 'Ponto de carga',
      dataIndex: 'pontoDeCarga',
      showSorterTooltip: false,
      sorter: (a, b) => a.pontoDeCarga!.localeCompare(b.pontoDeCarga!),
      ellipsis: true,
    },
    {
      title: '',
      width: '4%',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
      render: (idViagem) => (
        <Link target="_blank" to={`/relatorio/analise-viagem/${idViagem}`}>
          <Tooltip title="Visualizar">
            <SearchOutlined />
          </Tooltip>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    fetchCombosData();
    fetchConcreteMixers();
    dispatch(
      setHeader({
        title: 'Lista de remessas',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner spinning={loading}></Spinner>
      <div style={{ marginLeft: '2rem', marginTop: '1rem', padding: '1rem' }}>
        <BaseForm
          style={{
            width: '100%',
          }}
        >
          <Row gutter={12}>
            <Col xs={12} md={4}>
              <BaseFormInputItem label="Selecione o dia que deseja buscar">
                <DatePicker
                  className="ant-input"
                  placeholder="Selecione o dia que deseja buscar"
                  value={dateToSearch}
                  format={'DD-MM-YYYY'}
                  onChange={(e) => {
                    console.log(e);
                    setDateToSearch(e as moment.Moment);
                  }}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={4}>
              <BaseFormInputItem label="Selecione o tipo de filtro">
                <Select
                  showSearch
                  showArrow
                  onChange={(value) => setFilterType(value as number)}
                  value={filterType}
                  options={filterTypeOptions?.map((o) => ({
                    value: o.value,
                    label: o.label,
                  }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Selecione o tipo de filtro"
                ></Select>
              </BaseFormInputItem>
            </Col>

            {filterType === 1 && (
              <>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Selecione a betoneira">
                    <Select
                      showSearch
                      showArrow
                      onChange={(value) => {
                        setConcreteMixer(value as string);
                        setBranchToSearch(null);
                      }}
                      value={concreteMixer}
                      options={mixers?.map((m) => ({
                        value: m.numeroBT,
                        label: m.numeroBT,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Selecione a betoneira"
                    ></Select>
                  </BaseFormInputItem>
                </Col>
              </>
            )}

            {filterType === 2 && (
              <>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Selecione a regional">
                    <Select
                      showSearch
                      showArrow
                      onChange={(value) => handleChangeGroup(value as number)}
                      value={group?.id}
                      options={groups?.map((group) => ({
                        value: group.id,
                        label: group.nome,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Selecione a regional"
                    ></Select>
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Selecione a filial">
                    <Select
                      disabled={!group?.id}
                      value={branchToSearch}
                      showSearch
                      showArrow
                      onChange={(value) => {
                        setBranchToSearch(value as number);
                        setConcreteMixer(null);
                      }}
                      options={branches
                        ?.filter((cb) => cb.agrupamentos.idAgrupamento == group?.id && cb.codigoFilialSiac)
                        .map((cb) => ({ value: cb.codigoFilialSiac, label: cb.nome }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Selecione a filial"
                    />
                  </BaseFormInputItem>
                </Col>
              </>
            )}

            <Col style={{ marginTop: '1.75rem' }}>
              <Button
                disabled={
                  !dateToSearch || dateToSearch.toString().length <= 0 || loading || filterType === 1
                    ? !concreteMixer
                    : !branchToSearch
                }
                type="primary"
                onClick={handleFetchReportData}
                style={{ width: '14.75rem', height: '3.5rem' }}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </BaseForm>
      </div>
      <Dashboard
        title="Lista de Remessas"
        table={<Table bordered={true} columns={columns} dataSource={reportFiltered} />}
        placeholderTextSearch="Pesquisar"
        handleSearchOnChange={handleSearch}
      />
    </>
  );
};
