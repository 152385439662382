import appSettings from '@app/config/appsettings';
import { GoogleMap, GoogleMapProps, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as S from '../styles';
import { IconCaminhaoBetoneiraEsquerda } from '@app/assets/slump-icons';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';

const supermixLocation = {
  lat: -19.878877423869252,
  lng: -43.97931295928024,
};

const ConcreteMixerTrackingMap: React.FC = () => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState<number | null>(null);
  const travelInfo = useAppSelector((state) => state.concreteMixerLocations.concreteMixerLocations);

  const { isLoaded } = useJsApiLoader({
    id: 'mixer-dashboard-map',
    googleMapsApiKey: appSettings().MAPS_API_KEY, //process.env.REACT_APP_MAPS_API_KEY ?? '',
    libraries: ['places', 'geometry'],
  });

  const onMapLoad: GoogleMapProps['onLoad'] = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;

      map.setOptions({
        gestureHandling: 'cooperative',
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: true,
        zoom: 15,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      });

      if (map && controlsRef.current) {
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [travelInfo.listaInfoViagem.length],
  );

  const toggleTooltipVisibility = (index: number) => {
    setVisibleTooltipIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setVisibleTooltipIndex(null);
  };

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      const bounds = new google.maps.LatLngBounds();
      if (travelInfo.listaInfoViagem.length <= 0) {
        notificationController.warning({
          message: 'Não há informações de viagem para a betoneira selecionada nessa data',
        });
        return;
      }
      travelInfo.listaInfoViagem.forEach((loc) => bounds.extend(new google.maps.LatLng(loc.lat, loc.lng)));
      mapRef.current.fitBounds(bounds);
      mapRef.current?.panToBounds(bounds);
      mapRef.current.setZoom(15);
    }
  }, [isLoaded, travelInfo.listaInfoViagem]);

  return (
    <>
      {isLoaded && (
        <GoogleMap mapContainerStyle={S.GoogleMapStyled} onLoad={onMapLoad} center={supermixLocation}>
          {travelInfo.listaInfoViagem.length > 0 && (
            <OverlayView
              position={{
                lat: travelInfo.listaInfoViagem[travelInfo.listaInfoViagem.length - 1].lat,
                lng: travelInfo.listaInfoViagem[travelInfo.listaInfoViagem.length - 1].lng,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <S.ConcreteMixerMarker selected>
                <IconCaminhaoBetoneiraEsquerda />
              </S.ConcreteMixerMarker>
            </OverlayView>
          )}
          {travelInfo.listaInfoViagem?.length > 1 &&
            travelInfo.listaInfoViagem.map((loc, i) => (
              <OverlayView
                key={i}
                position={{ lat: loc.lat, lng: loc.lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <>
                  <S.LocationMarkerContainer onClick={() => toggleTooltipVisibility(i)} style={{}}>
                    <S.LocationMarker />
                  </S.LocationMarkerContainer>
                  {visibleTooltipIndex === i && (
                    <S.LocationMarkerTooltip>
                      <S.LocationMarkerTooltipHeader>Informações</S.LocationMarkerTooltipHeader>
                      <S.LocationMarkerTooltipBody>
                        <div>
                          Hora: {moment(loc?.dataHora).format('HH:mm:ss')}
                          <br />
                          Velocidade: {loc?.velocidadeBetoneira} km/h
                        </div>
                        <div>
                          Longitude: {loc?.lng}
                          <br />
                          Latitude: {loc?.lat}
                        </div>
                      </S.LocationMarkerTooltipBody>
                      <S.LocationMarkerTooltipCloseButton onClick={(e) => handleCloseClick(e)}>
                        <CloseOutlined />
                      </S.LocationMarkerTooltipCloseButton>
                    </S.LocationMarkerTooltip>
                  )}
                </>
              </OverlayView>
            ))}
        </GoogleMap>
      )}
    </>
  );
};

export default ConcreteMixerTrackingMap;
