import { FirstAccessModel } from '@app/domain/interfaces/auth/firstAccessModel';
import { LoginModel } from '@app/domain/interfaces/auth/loginModel';
import { LoginResponseModel } from '@app/domain/interfaces/auth/loginResponseModel';
import { NewPasswordModel } from '@app/domain/interfaces/auth/newPasswordModel';
import axios from 'axios';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import qs from 'qs';
import appSettings from '@app/config/appsettings';

interface AuthModel {}

export default interface IAuthService extends IApiServiceBase<AuthModel, AuthModel> {
  login(model: LoginModel): Promise<LoginResponseModel>;
  firstAccess(model: FirstAccessModel): Promise<boolean>;
  requestNewPassword(email: string): Promise<boolean>;
  setNewPassword(model: NewPasswordModel): Promise<boolean>;
}

export class AuthService extends ApiServiceBase<AuthModel, AuthModel> implements IAuthService {
  constructor() {
    super('admin/autenticacao');
  }

  async login(model: LoginModel): Promise<LoginResponseModel> {
    const { data } = await axios.create().post<LoginResponseModel>(
      `${appSettings().APIs.IdentityServer.UrlBase}/connect/token`,
      qs.stringify({
        client_id: appSettings().APIs.IdentityServer.ClientId,
        client_secret: appSettings().APIs.IdentityServer.ClientSecret,
        grant_type: appSettings().APIs.IdentityServer.GrantType,
        username: model.username,
        password: model.password,
      }),
    );

    return data;
  }

  async firstAccess(model: FirstAccessModel): Promise<boolean> {
    try {
      await this.api.post('primeiroAcesso', model);
      return true;
    } catch (error) {
      throw error;
    }
  }

  async requestNewPassword(email: string): Promise<boolean> {
    try {
      await this.api.post('enviarEmailAlterarSenha', { email: email });
      return true;
    } catch (error) {
      throw error;
    }
  }

  async setNewPassword(model: NewPasswordModel): Promise<boolean> {
    try {
      await this.api.post('alterarSenha', model);
      return true;
    } catch (error) {
      throw error;
    }
  }
}
